import { useState } from 'react';
import { CheckSquare, Clock, MoreHorizontal } from 'react-feather';
import CardInfo from '../CardInfo/CardInfo';
import Chip from '../Chip/Chip';
import Dropdown from '../Dropdown/Dropdown';
import './card.css';

export default function Card(props){

    const [showDropdown, setShowDropdown]= useState(false);
    const [showModal,setShowModal]= useState(false);

    return (
        <>
        {
           showModal &&  <CardInfo
            updateCard= {props.updateCard}
            card={props.card}
            boardId= {props.boardId}
            onClose={()=> setShowModal(false)} />
        }
       
        <div className="card" draggable 
        onDragEnd={()=> props.handleDragEnd(props.card?.id,props.boardId)}
        onDragEnter={()=> props.handleDragEnter(props.card?.id,props.boardId)}
        onClick={()=> setShowModal(true)}
        >
        
        
        
        <div className="card_top">
        <div className="card_label">
            {
                props.card?.labels?.map((item,index)=> <Chip 
                key={index}  
                text={item.text}
                  color={item.color}
                />) 
            }
         
        </div>
        <div className="card_top_more" onClick={()=> setShowDropdown(true)}>
                 <MoreHorizontal />
                 {
                     showDropdown && (
                        <Dropdown onClose= {()=> setShowDropdown(false)}>
                        <div className="card_dropdown">
                        <p onClick={()=> props.removeCard(props.card?.id,props.boardId)}>Delete Card</p>
                        </div>
                        </Dropdown>
                     )
                 }
                </div>
        </div>
        <div className="card_title">
            {props.card?.title}
        </div>
        <div className="card_footer">

            {
                props.card.date && (
                    <p> <Clock/> { props.card?.date}</p>
                )
            }

            {
                props.card?.tasks?.length>0 && (
                    <p>
                    <CheckSquare /> 
                    {props.card?.tasks?.filter((item)=> item.completed).length}/{props.card?.tasks?.length}
                </p>
                )
            }
           
        </div>
        </div>
        </>
    )
}